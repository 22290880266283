@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1743493230401/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1743493230401/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1743493230401/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-360-degree:before { content: "\EA01" }
.icon-addthis:before { content: "\EA02" }
.icon-arrow-down:before { content: "\EA03" }
.icon-arrow-left:before { content: "\EA04" }
.icon-arrow-long-down:before { content: "\EA05" }
.icon-arrow-long-left:before { content: "\EA06" }
.icon-arrow-long-right:before { content: "\EA07" }
.icon-arrow-long-top:before { content: "\EA08" }
.icon-arrow-right:before { content: "\EA09" }
.icon-bearbeiten:before { content: "\EA0A" }
.icon-cart:before { content: "\EA0B" }
.icon-check:before { content: "\EA0C" }
.icon-close:before { content: "\EA0D" }
.icon-contact:before { content: "\EA0E" }
.icon-delete:before { content: "\EA0F" }
.icon-down:before { content: "\EA10" }
.icon-download:before { content: "\EA11" }
.icon-drucken:before { content: "\EA12" }
.icon-duplizieren:before { content: "\EA13" }
.icon-elements:before { content: "\EA14" }
.icon-email-teilen:before { content: "\EA15" }
.icon-facebook:before { content: "\EA16" }
.icon-fax:before { content: "\EA17" }
.icon-fragezeichen-einzeln:before { content: "\EA18" }
.icon-information-i:before { content: "\EA19" }
.icon-information-square:before { content: "\EA1A" }
.icon-instagram:before { content: "\EA1B" }
.icon-kalender-filled:before { content: "\EA1C" }
.icon-kalender:before { content: "\EA1D" }
.icon-lamp:before { content: "\EA1E" }
.icon-left:before { content: "\EA1F" }
.icon-linkedin:before { content: "\EA20" }
.icon-mail-outline:before { content: "\EA21" }
.icon-mail:before { content: "\EA22" }
.icon-merkliste-active:before { content: "\EA23" }
.icon-merkliste:before { content: "\EA24" }
.icon-mobile:before { content: "\EA25" }
.icon-multiple-img:before { content: "\EA26" }
.icon-notice:before { content: "\EA27" }
.icon-phone:before { content: "\EA28" }
.icon-pinnwand:before { content: "\EA29" }
.icon-pinterest:before { content: "\EA2A" }
.icon-play:before { content: "\EA2B" }
.icon-power:before { content: "\EA2C" }
.icon-power_feeder:before { content: "\EA2D" }
.icon-quote:before { content: "\EA2E" }
.icon-restart:before { content: "\EA2F" }
.icon-right:before { content: "\EA30" }
.icon-search-bold:before { content: "\EA31" }
.icon-search-tryout:before { content: "\EA32" }
.icon-search:before { content: "\EA33" }
.icon-strex-connector:before { content: "\EA34" }
.icon-strex-track-corner:before { content: "\EA35" }
.icon-strex-track-line:before { content: "\EA36" }
.icon-strex-track-square:before { content: "\EA37" }
.icon-strex-track-u:before { content: "\EA38" }
.icon-teilen:before { content: "\EA39" }
.icon-twitter:before { content: "\EA3A" }
.icon-up:before { content: "\EA3B" }
.icon-upload:before { content: "\EA3C" }
.icon-user:before { content: "\EA3D" }
.icon-whatsapp:before { content: "\EA3E" }
.icon-wireless_control:before { content: "\EA3F" }
.icon-youtube:before { content: "\EA40" }


:root {
--icon-360-degree: "\EA01";
    --icon-addthis: "\EA02";
    --icon-arrow-down: "\EA03";
    --icon-arrow-left: "\EA04";
    --icon-arrow-long-down: "\EA05";
    --icon-arrow-long-left: "\EA06";
    --icon-arrow-long-right: "\EA07";
    --icon-arrow-long-top: "\EA08";
    --icon-arrow-right: "\EA09";
    --icon-bearbeiten: "\EA0A";
    --icon-cart: "\EA0B";
    --icon-check: "\EA0C";
    --icon-close: "\EA0D";
    --icon-contact: "\EA0E";
    --icon-delete: "\EA0F";
    --icon-down: "\EA10";
    --icon-download: "\EA11";
    --icon-drucken: "\EA12";
    --icon-duplizieren: "\EA13";
    --icon-elements: "\EA14";
    --icon-email-teilen: "\EA15";
    --icon-facebook: "\EA16";
    --icon-fax: "\EA17";
    --icon-fragezeichen-einzeln: "\EA18";
    --icon-information-i: "\EA19";
    --icon-information-square: "\EA1A";
    --icon-instagram: "\EA1B";
    --icon-kalender-filled: "\EA1C";
    --icon-kalender: "\EA1D";
    --icon-lamp: "\EA1E";
    --icon-left: "\EA1F";
    --icon-linkedin: "\EA20";
    --icon-mail-outline: "\EA21";
    --icon-mail: "\EA22";
    --icon-merkliste-active: "\EA23";
    --icon-merkliste: "\EA24";
    --icon-mobile: "\EA25";
    --icon-multiple-img: "\EA26";
    --icon-notice: "\EA27";
    --icon-phone: "\EA28";
    --icon-pinnwand: "\EA29";
    --icon-pinterest: "\EA2A";
    --icon-play: "\EA2B";
    --icon-power: "\EA2C";
    --icon-power_feeder: "\EA2D";
    --icon-quote: "\EA2E";
    --icon-restart: "\EA2F";
    --icon-right: "\EA30";
    --icon-search-bold: "\EA31";
    --icon-search-tryout: "\EA32";
    --icon-search: "\EA33";
    --icon-strex-connector: "\EA34";
    --icon-strex-track-corner: "\EA35";
    --icon-strex-track-line: "\EA36";
    --icon-strex-track-square: "\EA37";
    --icon-strex-track-u: "\EA38";
    --icon-teilen: "\EA39";
    --icon-twitter: "\EA3A";
    --icon-up: "\EA3B";
    --icon-upload: "\EA3C";
    --icon-user: "\EA3D";
    --icon-whatsapp: "\EA3E";
    --icon-wireless_control: "\EA3F";
    --icon-youtube: "\EA40";
    
}